<template>
  <div>
    <section class="goal">
      <h2>CRUDシステム</h2>
      <div class="wrap">
        <input v-model="newtodo" type="text" />
        <button @click="createTodo">create</button>
        <hr />
        <ul>
          <li v-for="(todo, index) in todos" :key="index">
            <input type="checkbox" v-model="todo.isDone" />
            <span :class="{ done: todo.isDone }">{{ todo.content }}</span>
            <button @click="deleteTodo(todo.content, index)">delete</button>
          </li>
        </ul>
      </div>
    </section>
    <hr class="border" />
    <p class="border_txt">これ以下は模写不要</p>
    <section class="question">
      <h2>問題</h2>
      <div class="wrap">
        <p class="question_txt">以上のアプリケーションを作成しなさい</p>
      </div>
    </section>
    <section class="answer">
      <h2>回答</h2>
      <div v-if="showAnswer">
        <button class="answer_btn" @click="showAnswer = false">
          回答を非表示
        </button>
        <div class="wrap">
          <pre>
            <code v-pre>             
&lt;template&gt;
  &lt;div&gt;
    &lt;input v-model="newtodo" type="text" /&gt;
    &lt;button @click="createTodo"&gt;create&lt;/button&gt;
    &lt;hr /&gt;
    &lt;ul&gt;
      &lt;li v-for="(todo, index) in todos" :key="index"&gt;
        &lt;input type="checkbox" v-model="todo.isDone" /&gt;
        &lt;span :class="{ done: todo.isDone }"&gt;{{ todo.content }}&lt;/span&gt;
        &lt;button @click="deleteTodo(todo.content, index)"&gt;delete&lt;/button&gt;
      &lt;/li&gt;
    &lt;/ul&gt;
  &lt;/div&gt;
&lt;/template&gt;

&lt;script&gt;
export default {
  data() {
    return {
      newtodo: "",
      todos: [],
    };
  },
  methods: {
    createTodo() {
      let todo = {
        isDone: false,
        content: this.newtodo,
      };
      if (this.newtodo != "") {
        this.todos.push(todo);
      }
      this.newtodo = "";
    },
    deleteTodo(content, index) {
      if (!confirm("「" + content + "」を削除しますか？")) return;
      this.todos.splice(index, 1);
    },
  },
};
&lt;/script&gt;
&lt;style lang="scss" scoped&gt;
.done {
  opacity: 0.5;
  text-decoration: line-through;
}
li {
  list-style: none;
  margin-bottom: 10px;
}
span,
input,
button {
  margin-right: 5px;
}
hr {
  margin: 20px 0;
}
&lt;/style&gt;
            </code>
          </pre>
        </div>
      </div>
      <button v-else class="answer_btn" @click="showAnswer = true">
        回答を表示
      </button>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showAnswer: false,
      newtodo: "",
      todos: [],
    };
  },
  methods: {
    createTodo() {
      let todo = {
        isDone: false,
        content: this.newtodo,
      };
      if (this.newtodo != "") {
        this.todos.push(todo);
      }
      this.newtodo = "";
    },
    deleteTodo(content, index) {
      if (!confirm("「" + content + "」を削除しますか？")) return;
      this.todos.splice(index, 1);
    },
  },
};
</script>
<style lang="scss" scoped>
.done {
  opacity: 0.5;
  text-decoration: line-through;
}
li {
  list-style: none;
  margin-bottom: 10px;
}
span,
input,
button {
  margin-right: 5px;
}
hr {
  margin: 20px 0;
}
</style>